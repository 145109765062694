import { Injectable } from '@angular/core';
import { UtilityFunctions } from '../utility/utils';

@Injectable({
  providedIn: 'root'
})
export class AssessmentService {
  userData: any;

  constructor(
    public utils:UtilityFunctions
  ) {
    
  }



  encriptPer(authorizationData:any) {
    let encObj:any = {
      allowAdd_enc:this.utils.getDecryptedValue(authorizationData.allowAdd_enc),
      allowDelete_enc:this.utils.getDecryptedValue(authorizationData.allowDelete_enc),
      allowDownload_enc:this.utils.getDecryptedValue(authorizationData.allowDownload_enc),
      allowUpdate_enc:this.utils.getDecryptedValue(authorizationData.allowUpdate_enc),
      allowView_enc:this.utils.getDecryptedValue(authorizationData.allowView_enc),
      actionPageId:authorizationData.pk_pageId,
      actionPageId_enc:authorizationData.pk_pageId_enc
    }
    return encObj
  }

  setLocationId(list:any) {
    this.userData = JSON.parse(sessionStorage.getItem('userData')!);
    let locationId;
    list.forEach((ele: any) => {
      if (ele.pk_locationId == this.userData.fk_locationId) {
       locationId= ele['pk_locationId'];
      }
    })
    return locationId;
  }

  dataURItoBlob(dataURI:any) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array]);
    return blob;
  }

  getErrorMessage(error:any) {
    console.log(error);
    if(error['status'] == 400 && error.error['message']) {
      return error.error['message']
    } else if (error['status'] == 500 ) {
      return 'Internal server error, Please contact to administrator'
    } else {
      return 'Some error has been occurred, Please contact to administrator'
    }
  }


}
