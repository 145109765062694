import { environment } from "src/environments/environment.development"

export const API_PATH: any = {
    'GENERATE_SALT': environment.basePath + environment.basePort + 'api/Home/GenerateSalt',
    'LOGIN_API': environment.basePath + environment.basePort + 'api/Home/Index',
    'Logout': environment.basePath + environment.basePort + 'api/Home/Logout',
    'Forgotpassword': environment.basePath + environment.basePort + 'api/Home/Forgotpassword',
    'Changepassword': environment.basePath + environment.basePort + 'api/Home/ChangePassword',

    //District Setup
    'DistrictGet': environment.basePath + environment.basePort + 'api/ACM/DistrictGet',
    'DistrictSearch': environment.basePath + environment.basePort + 'api/ACM/DistrictSearch',
    'AddDistrict': environment.basePath + environment.basePort + 'api/ACM/AddDistrict',
    'District_Upd': environment.basePath + environment.basePort + 'api/ACM/District_Upd',
    'DistrictDel': environment.basePath + environment.basePort + 'api/ACM/DistrictDel',
    'GetMenuAccess': environment.basePath + environment.basePort + 'api/Home/GetMenuAccess',

    //SetUp Page
    'Page_Search': environment.basePath + environment.basePort + 'api/UM/UM_Page_Search',
    'Module_SelForDDL': environment.basePath + environment.basePort + 'api/UM/UM_Module_SelForDDL',
    'PageType_SelForDDL': environment.basePath + environment.basePort + 'api/UM/UM_PageType_SelForDDL',
    'AddPageMst': environment.basePath + environment.basePort + 'api/UM/AddPageMst',
    'UpdatePageMst': environment.basePath + environment.basePort + 'api/UM/UpdatePageMst',
    'Page_Get': environment.basePath + environment.basePort + 'api/UM/UM_Page_Get',
    'Page_Del': environment.basePath + environment.basePort + 'api/UM/UM_Page_Del',


    //dropdowns
    'stateDDL': environment.basePath + environment.basePort + 'api/ACM/ACM_State_SelForDDL',
    'CountryDDL': environment.basePath + environment.basePort + 'api/ACM/ACM_Country_SelForDDL',
    'Block_SelForDDL': environment.basePath + environment.basePort + 'api/ACM/ACM_Block_SelForDDL',
    'CountryDDLById': environment.basePath + environment.basePort + 'api/ACM/ACM_Country_byId_SelForDDL',
    'Block_DDLById': environment.basePath + environment.basePort + 'api/ACM/ACM_Block_byId_SelForDDL',
    'DistrictDDLById': environment.basePath + environment.basePort + 'api/ACM/ACM_District_byId_SelectForDDl',
    'getCountryDDL': environment.basePath + environment.basePort + 'api/ACM/ACM_Country_SelForDDL',
    'getCommon_StateDDL': environment.basePath + environment.basePort + 'api/ACM/ACM_State_Current_SelForDDl',

    //Organization setup
    'UpdateOrganization': environment.basePath + environment.basePort + 'api/ACM/UpdateOrganization',
    'GetOrganization': environment.basePath + environment.basePort + 'api/ACM/GetOrganization',
    'DistrictDDL': environment.basePath + environment.basePort + 'api/ACM/ACM_District_SelForDDL',

    //Module Setup Page
    'ModuleSearch': environment.basePath + environment.basePort + 'api/UM/Module_Search',
    // 'Module_SelForDDL': environment.basePath + environment.basePort +'api/UM/UM_Module_SelForDDL',
    // 'PageType_SelForDDL': environment.basePath + environment.basePort +'api/UM/UM_PageType_SelForDDL',
    'AddModule': environment.basePath + environment.basePort + 'api/UM/AddModule',
    'Module_Upd': environment.basePath + environment.basePort + 'api/UM/UpdateModule',
    'ModuleGet': environment.basePath + environment.basePort + 'api/UM/GetModule',
    'ModuleDel': environment.basePath + environment.basePort + 'api/UM/DeleteModule',

    //Academic Year Page
    'FinancialYearSearch': environment.basePath + environment.basePort + 'api/ACM/FinancialYearSearch',
    'AddAcademicYear': environment.basePath + environment.basePort + 'api/ACM/AddFinancialYear',
    'AcademicYear_Upd': environment.basePath + environment.basePort + 'api/ACM/FinancialYear_Upd',
    'AcademicYearGet': environment.basePath + environment.basePort + 'api/ACM/FinancialYearGet',
    'AcademicYearDelete': environment.basePath + environment.basePort + 'api/ACM/FinancialYearDelete',
    'AcademicYearDDL': environment.basePath + environment.basePort + 'api/ACM/ACM_AcademicYear_SelForDDL',
    'Month_Display_Search': environment.basePath + environment.basePort + 'api/ACM/MonthSearch',
    'Year_Display_Search': environment.basePath + environment.basePort + 'api/ACM/YearSearch',
    'Gender_Display_Search': environment.basePath + environment.basePort + 'api/ACM/GenderSearch',
    'PageType_Display_Search': environment.basePath + environment.basePort + 'api/UM/PageTypeSearch',

    // STATE PAGE DDL 
    'StateSearch': environment.basePath + environment.basePort + 'api/ACM/ACMStateSearch',
    'StateDelete': environment.basePath + environment.basePort + 'api/ACM/ACMStateDel',
    'StateGet': environment.basePath + environment.basePort + 'api/ACM/ACMStateGet',
    'StateADD': environment.basePath + environment.basePort + 'api/ACM/AddACMState',
    'StateUpdate': environment.basePath + environment.basePort + 'api/ACM/ACMState_Upd',

    // User Registration 
    'UserRegSearch': environment.basePath + environment.basePort + 'api/UM/UM_User_Search',
    'UserRegAdd': environment.basePath + environment.basePort + 'api/UM/AddUser',
    'UserRegUpd': environment.basePath + environment.basePort + 'api/UM/UpdateUser',
    'UserRegGet': environment.basePath + environment.basePort + 'api/UM/UM_User_Get',
    'UserRegDel': environment.basePath + environment.basePort + 'api/UM/UM_User_Del',
    'LocationDDL': environment.basePath + environment.basePort + 'api/UM/UM_Location_SelForDDL',
    'UserRoleDDL': environment.basePath + environment.basePort + 'api/UM/UM_Role_SelForDDL',
    'UserRoleByIdDDL': environment.basePath + environment.basePort + 'api/UM/UM_Role_SelByIdForDDL',
    'UsersendMail': environment.basePath + environment.basePort + 'api/UM/SendCredMail',
    'UserByIdDDL': environment.basePath + environment.basePort + 'api/UM/UM_User_By_loc_SelForDDL',
    'UserRoleBySelect_IdDDL': environment.basePath + environment.basePort + 'api/UM/UM_Role_ById_SelectForDDL',



    //Block Setup
    'BlockSearch': environment.basePath + environment.basePort + 'api/ACM/BlockSearch',
    'BlockDel': environment.basePath + environment.basePort + 'api/ACM/BlockDel',
    'AddBlock': environment.basePath + environment.basePort + 'api/ACM/AddBlock',
    'Block_Upd': environment.basePath + environment.basePort + 'api/ACM/Block_Upd',
    'BlockGet': environment.basePath + environment.basePort + 'api/ACM/BlockGet',

    // country search
    'countrysearch': environment.basePath + environment.basePort + 'api/ACM/CountrySearch',


    //Class Setup
    'Class_INSERT': environment.basePath + environment.basePort + 'api/ASS/ASSClassAdd',
    'Class_Search': environment.basePath + environment.basePort + 'api/ASS/ASSClassSearch',
    'Class_Get': environment.basePath + environment.basePort + 'api/ASS/ASSClassGet',
    'Class_Update': environment.basePath + environment.basePort + 'api/ASS/ASSClassUpdate',
    'Class_Del': environment.basePath + environment.basePort + 'api/ASS/ASSClassDel',

    //Section Setup
    'Section_INSERT': environment.basePath + environment.basePort + 'api/ASS/ASSSectionAdd',
    'Section_Search': environment.basePath + environment.basePort + 'api/ASS/ASSSectionSearch',
    'Section_Get': environment.basePath + environment.basePort + 'api/ASS/ASSSectionGet',
    'Section_Update': environment.basePath + environment.basePort + 'api/ASS/ASSSectionUpdate',
    'Section_Delete': environment.basePath + environment.basePort + 'api/ASS/ASSSectionDel',


    //School Type
    'SchoolTypeSearch': environment.basePath + environment.basePort + 'api/ASS/ASSSchoolTypeSearch',
    'SchoolTypeAdd': environment.basePath + environment.basePort + 'api/ASS/ASSSchoolTypeAdd',
    'SchoolType_Upd': environment.basePath + environment.basePort + 'api/ASS/ASSSchooltypeUpdate',
    'SchoolTypeGet': environment.basePath + environment.basePort + 'api/ASS/ASSSchoolTypeGet',
    'SchoolTypeDel': environment.basePath + environment.basePort + 'api/ASS/ASSSchoolTypeDel',

    //Page Actiom Map
    'PageAction_Map_Search': environment.basePath + environment.basePort + 'api/UM/UM_PageAction_Map_Search',
    'UM_Page_SelForDDL': environment.basePath + environment.basePort + 'api/UM/UM_Page_SelForDDL',
    'UM_Page_SelIdForDDL': environment.basePath + environment.basePort + 'api/UM/UM_Page_SelIdForDDL',

    //Group Api
    'GroupSearch': environment.basePath + environment.basePort + 'api/ASS/ASSGroupSearch',
    'GroupAdd': environment.basePath + environment.basePort + 'api/ASS/ASSGroupAdd',
    'GroupUpdate': environment.basePath + environment.basePort + 'api/ASS/ASSGroupUpdate',
    'GroupGet': environment.basePath + environment.basePort + 'api/ASS/ASSGroupGet',
    'GroupDel': environment.basePath + environment.basePort + 'api/ASS/ASSGroupDel',
    'UM_User_SelForDDL': environment.basePath + environment.basePort + 'api/UM/UM_User_SelForDDL',

    //addional Permission 
    'UserRole_Rights_Search': environment.basePath + environment.basePort + 'api/UM/UM_UserRole_Rights_Search',
    'AddUserRoleRights': environment.basePath + environment.basePort + 'api/UM/AddUserRoleRights',
    'UpdateUserRole_Rights': environment.basePath + environment.basePort + 'api/UM/UpdateUserRoleRights',
    'UserRole_Rights_Get': environment.basePath + environment.basePort + 'api/UM/UM_UserRole_Rights_Get',
    'UserRole_Rights_Del': environment.basePath + environment.basePort + 'api/UM/UM_UserRole_Rights_Del',

    //assign-permission-to-role
    'Assignpertorolesearch': environment.basePath + environment.basePort + 'api/UM/UM_RolePage_Rights_Search',
    'assignpertoroleadd': environment.basePath + environment.basePort + 'api/UM/AddRolePageRights',
    //Email Configuration
    'EmailConfig_Mst_Search': environment.basePath + environment.basePort + 'api/ACM/ACM_EmailConfig_Mst_Search',
    'EmailConfig_Mst_Del': environment.basePath + environment.basePort + 'api/ACM/ACM_EmailConfig_Mst_Del',
    'EmailConfig_Mst_Ins': environment.basePath + environment.basePort + 'api/ACM/ACM_EmailConfig_Mst_Ins',
    'EmailConfig_Mst_Upd': environment.basePath + environment.basePort + 'api/ACM/ACM_EmailConfig_Mst_Upd',
    'EmailConfig_Mst_Get': environment.basePath + environment.basePort + 'api/ACM/ACM_EmailConfig_Mst_Get',

    //Teacher Setup
    'TeacherSearchForAlert': environment.basePath + environment.basePort + 'api/School/TeacherSearchForAlert',
    'Teacher_School_SelForDDL': environment.basePath + environment.basePort + 'api/School/GetSchoolForTeacherAlert',
    'AddTeacher': environment.basePath + environment.basePort + 'api/School/AddTeacher',
    'TeacherUpdate': environment.basePath + environment.basePort + 'api/School/TeacherUpdate',
    'TeacherGet': environment.basePath + environment.basePort + 'api/School/TeacherGet',
    'TeacherDel': environment.basePath + environment.basePort + 'api/School/TeacherDel',
    'GenderSelForDDL': environment.basePath + environment.basePort + 'api/ACM/GenderSelForDDL',

    //password reset In UM
    'passwordResetSearch': environment.basePath + environment.basePort + 'api/UM/ResetPasswordUserSearch',
    'passwordResetMail': environment.basePath + environment.basePort + 'api/UM/ResetPasswordSendCredMail',
    'UserblockUnblock': environment.basePath + environment.basePort + 'api/UM/UserBlockandUnblock',

    //Language Page
    'Language_Search': environment.basePath + environment.basePort + 'api/ACM/LanguageSearch',
    'Add_Language': environment.basePath + environment.basePort + 'api/ACM/AddLanguage',

    // 'Language_Update': environment.basePath + environment.basePort + 'api/ACM/AcademicYear_Upd',
    'Language_Get': environment.basePath + environment.basePort + 'api/ACM/LanguageGet',
    'Language_Delete': environment.basePath + environment.basePort + 'api/ACM/LanguageDel',

    //Teacher Import
    'UploadExcelDocument': environment.basePath + environment.basePort + 'api/School/UploadExcelDocument',

    //Location School
    'Location_Search': environment.basePath + environment.basePort + 'api/UM/UM_Location_Search',
    'Location_Del': environment.basePath + environment.basePort + 'api/UM/UM_Location_Del',
    'SchoolType_SelForDDL': environment.basePath + environment.basePort + 'api/UM/UM_SchoolType_SelectForDDl',
    'SchooCategory_SelectForDDl': environment.basePath + environment.basePort + 'api/UM/UM_SchooCategory_SelectForDDl',
    'AddLocationMst': environment.basePath + environment.basePort + 'api/UM/AddLocationMst',
    'UpdateLocationMst': environment.basePath + environment.basePort + 'api/UM/UpdateLocationMst',
    'Location_Get': environment.basePath + environment.basePort + 'api/UM/UM_Location_Get',

    //Role Setup
    'Role_Search': environment.basePath + environment.basePort + 'api/UM/UM_Role_Search',
    'Role_Del': environment.basePath + environment.basePort + 'api/UM/UM_Role_Del',
    'AddRoleMst': environment.basePath + environment.basePort + 'api/UM/AddRoleMst',
    'UpdateRoleMst': environment.basePath + environment.basePort + 'api/UM/UpdateRoleMst',
    'Role_Get': environment.basePath + environment.basePort + 'api/UM/UM_Role_Get',

    //send credentinals to teacher
    'GetSchoolForTeacherAlert': environment.basePath + environment.basePort + 'api/School/GetSchoolForTeacherAlert',
    'TeacherSearchForCredentials': environment.basePath + environment.basePort + 'api/School/TeacherSearchForAlert',

    //Student setup Api 
    'Student_Search': environment.basePath + environment.basePort + 'api/School/StudentSearchForAlert',
    'Student_Get': environment.basePath + environment.basePort + 'api/School/StudentGet',
    'Student_Add': environment.basePath + environment.basePort + 'api/School/AddStudent',
    'Student_Update': environment.basePath + environment.basePort + 'api/School/StudentUpdate',
    'Student_Del': environment.basePath + environment.basePort + 'api/School/StudentDel',
    'Class_DDL': environment.basePath + environment.basePort + 'api/ASS/ASS_Class_SelForDDL',
    'Section_DDL': environment.basePath + environment.basePort + 'api/ASS/ASS_Section_SelForDDL',








    // **********************Dispatch *******************************

    //Item Setup Api 
    'itemsetup_Search': environment.basePath + environment.basePort + 'api/DIS/ItemSearch',
    'itemsetup_Get': environment.basePath + environment.basePort + 'api/DIS/ItemGet',
    'itemsetup_Add': environment.basePath + environment.basePort + 'api/DIS/AddItem',
    'itemsetup_Update': environment.basePath + environment.basePort + 'api/DIS/Item_Upd',
    'itemsetup_Delete': environment.basePath + environment.basePort + 'api/DIS/ItemDel',

    'Status_Display': environment.basePath + environment.basePort + 'api/UM/StatusSearch',

    //Class Setup
    'ClassSearch': environment.basePath + environment.basePort + 'api/DIS/ClassSearch',
    'ClassDel': environment.basePath + environment.basePort + 'api/DIS/ClassDel',
    'Class_Upd': environment.basePath + environment.basePort + 'api/DIS/Class_Upd',
    'Class_Ins': environment.basePath + environment.basePort + 'api/DIS/Class_Ins',
    'ClassGet': environment.basePath + environment.basePort + 'api/DIS/ClassGet',

    // Get Manu Access Api
    'DispatchGetMenuAccess': environment.basePath + environment.basePort + 'api/Home/GetMenuAccess',
    // Acadmicyear    api
    'search_Acadmicyear': environment.basePath + environment.basePort + 'api/UM/ActionTypeList',


    //item Purchase
    'Purchase_Mst_INSERT': environment.basePath + environment.basePort + 'api/DIS/DIS_Purchase_Mst_Ins',
    'Purchase_Mst_UPDATE': environment.basePath + environment.basePort + 'api/DIS/DIS_Purchase_Mst_Upd',
    'Purchase_Mst_SEARCH': environment.basePath + environment.basePort + 'api/DIS/DIS_Purchase_Mst_Search',
    'Purchase_Mst_GET': environment.basePath + environment.basePort + 'api/DIS/DIS_Purchase_Mst_Get',
    'Purchase_Mst_VIEW': environment.basePath + environment.basePort + 'api/DIS/DIS_Purchase_Mst_View',
    'Purchase_Mst_DEL': environment.basePath + environment.basePort + 'api/DIS/DIS_Purchase_Mst_Del',

    //CLASS DDL
    'DIS_Class_DDL': environment.basePath + environment.basePort + 'api/DIS/DIS_Class_SelForDDL',
    'DIS_ITEM_DDL': environment.basePath + environment.basePort + 'api/DIS/DIS_Item_SelForDDL',
    'DIS_Location_DDL': environment.basePath + environment.basePort + 'api/UM/UM_Location_SelForDDL',

    //item Challan DDL & API
    'Location_DDL': environment.basePath + environment.basePort + 'api/UM/UM_Location_SelForDDL',
    'INSERT_challanitem,': environment.basePath + environment.basePort + 'api/DIS/AddItemDispatchByChallan',
    'UPDATE_challanitem': environment.basePath + environment.basePort + 'api/DIS/UpdateItemDisByChallanDisPatch',
    'GET_challanitem': environment.basePath + environment.basePort + 'api/DIS/DIS_Item_Dispatch_By_Challan_Get',
    'View_challanitem': environment.basePath + environment.basePort + 'api/DIS/DIS_Item_Dispatch_By_Challan_View',
    'DEL_challanitem': environment.basePath + environment.basePort + 'api/DIS/DIS_Item_Dispatch_By_Challan_Del',
    'SEARCH_challanitem': environment.basePath + environment.basePort + 'api/DIS/DIS_Item_Dispatch_By_Challan_Search',


    //Item damaged
    'Search_DamagedItem': environment.basePath + environment.basePort + 'api/DIS/Search_DamagedItem',
    'Get_DamagedItem': environment.basePath + environment.basePort + 'api/DIS/Get_DamagedItem',
    'Add_DamagedItem': environment.basePath + environment.basePort + 'api/DIS/Add_DamagedItem',
    'Update_DamagedItem': environment.basePath + environment.basePort + 'api/DIS/Update_DamagedItem',
    'Delete_DamagedItem': environment.basePath + environment.basePort + 'api/DIS/Delete_DamagedItem',
    'View_DamagedItem': environment.basePath + environment.basePort + 'api/DIS/View_DamagedItem',
    //item HAND & API
    //'Location_DDL': environment.basePath + environment.basePort + 'api/UM/UM_Location_SelForDDL',
    'INSERT_handitem,': environment.basePath + environment.basePort + 'api/DIS/AddItemDispatch',
    'UPDATE_handitem': environment.basePath + environment.basePort + 'api/DIS/UpdateItemDisPatch',
    'GET_handitem': environment.basePath + environment.basePort + 'api/DIS/DIS_Item_Dispatch_Get',
    'View_handitem': environment.basePath + environment.basePort + 'api/DIS/DIS_Item_Dispatch_View',
    'DEL_handitem': environment.basePath + environment.basePort + 'api/DIS/DIS_Item_Dispatch_Del',
    'SEARCH_handitem': environment.basePath + environment.basePort + 'api/DIS/DIS_Item_Dispatch_Search',
    'getitem_quantity': environment.basePath + environment.basePort + 'api/DIS/DIS_Available_Qunty_Get',

    'UM_Location_By_Id_SelForDDL': environment.basePath + environment.basePort + 'api/UM/UM_Location_By_Id_SelForDDL',
    'DIS_Item_Dispatch_Search': environment.basePath + environment.basePort + 'api/DIS/DIS_Item_Dispatch_Search',
    'AddItemDispatch': environment.basePath + environment.basePort + 'api/DIS/AddItemDispatch',
    'DIS_Item_Dispatch_Get': environment.basePath + environment.basePort + 'api/DIS/DIS_Item_Dispatch_Get',
    'DIS_Item_Dispatch_View': environment.basePath + environment.basePort + 'api/DIS/DIS_Item_Dispatch_View',
    'DIS_Item_Dispatch_Del': environment.basePath + environment.basePort + 'api/DIS/DIS_Item_Dispatch_Del',
    'DIS_Item_QR_Code_Generate': environment.basePath + environment.basePort + 'api/DIS/DIS_Item_QR_Code_Generate',




    //shipment receiving
    'DIS_Item_Dispatch_By_QRCode_Get': environment.basePath + environment.basePort + 'api/DIS/DIS_Item_Dispatch_By_QRCode_Get',
    'DIS_Item_Dispatch_By_QRCode_View': environment.basePath + environment.basePort + 'api/DIS/DIS_Item_Dispatch_By_QRCode_View',
    'DIS_Item_Dispatch_QRCode_By_Dispatch_View': environment.basePath + environment.basePort + 'api/DIS/DIS_Item_Dispatch_QRCode_By_Dispatch_View',
    'DIS_Item_Dispatch_By_QRCode_Upd': environment.basePath + environment.basePort + 'api/DIS/DIS_Item_Dispatch_By_QRCode_Upd',

    //Stock Report
    'Stock_Register_Rpt': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/DIS_Stock_Register_Rpt',
    'UM_Role_Report': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/Role_PDF_EXCEL',
    'UM_Role_Permission_Report': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/Role_Permission_PDF_EXCEL',
    //'Stock_Register_Rpt': environment.basePath + environment.baseReportPort + 'api/ReportView/DIS_Stock_Register_Rpt',
    // 'UM_Role_Report': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/Role_PDF_EXCEL',
    // 'UM_Role_Permission_Report': environment.basePath + environment.baseReportPort + 'api/ReportView/Role_Permission_PDF_EXCEL',
    // 'Stock_Register_Rpt': environment.basePath + environment.basePort + 'api/ReportView/DIS_Stock_Register_Rpt',
    // 'UM_Role_Report': environment.basePath + environment.basePort + 'api/ReportView/UM_Role_Rpt',
    // 'UM_Role_Permission_Report': environment.basePath + environment.basePort + 'api/ReportViewer/UM_Role_Permission_Rpt',
    'ItemPurchaseDetails_RepotsDwnld': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/DIS_ItemPurchaseDetails_Rpt',
    'ItemDemageDetails_RepotsDwnld': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/DIS_ItemDamagedDetails_Rpt',


    //Shipment Return
    'Search_ShipmentReturn': environment.basePath + environment.basePort + 'api/DIS/Search_ShipmentReturn',
    'Add_ShipmentReturn': environment.basePath + environment.basePort + 'api/DIS/Add_ShipmentReturn',
    'Update_ShipmentReturn': environment.basePath + environment.basePort + 'api/DIS/Update_ShipmentReturn',
    'GetShipmentReturn': environment.basePath + environment.basePort + 'api/DIS/Get_ShipmentReturn',
    'Delete_ShipmentReturn': environment.basePath + environment.basePort + 'api/DIS/Delete_ShipmentReturn',

    'User_Profile_Data_Get': environment.basePath + environment.basePort + 'api/UM/UM_User_Profile_Data_Get',
    'UM_User_List_Search': environment.basePath + environment.basePort + 'api/UM/UM_User_List_Search',


    'DIS_Item_QR_Code_Generate_Get': environment.basePath + environment.basePort + 'api/PDFReport/DIS_Item_QR_Code_Generate_Get',
    //Item Indent
    'FinancialYear_SelectForDDl': environment.basePath + environment.basePort + 'api/ACM/ACM_FinancialYear_SelForDDL',
    'Blocklist_Get': environment.basePath + environment.basePort + 'api/DIS/DIS_Blocklist_Get',
    'ItemIndent_Mst_Search': environment.basePath + environment.basePort + 'api/DIS/DIS_ItemIndent_Mst_Search',
    'ItemIndent_Mst_Del': environment.basePath + environment.basePort + 'api/DIS/DIS_ItemIndent_Mst_Del',
    'ItemIndent_Mst_Ins': environment.basePath + environment.basePort + 'api/DIS/DIS_ItemIndent_Mst_Ins',
    'ItemIndent_Mst_Get': environment.basePath + environment.basePort + 'api/DIS/DIS_ItemIndent_Mst_Get',
    'User_RepotsDwnld': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/User_PDF_EXCEL',

    'Users_GetlocationCascade_DDL': environment.basePath + environment.basePort + 'api/UM/UM_Location_By_Id_SelForDDL',
    'User_Permission_RepotsDwnld': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/User_Permission_PDF_EXCEL',


    'ItemIndent_Mst_Upd': environment.basePath + environment.basePort + 'api/DIS/DIS_ItemIndent_Mst_Upd',
    'DIS_Item_Dispatch_Per_Search': environment.basePath + environment.basePort + 'api/DIS/DIS_Item_Dispatch_Per_Search',
    'UM_User_By_Loc_SelForDDL': environment.basePath + environment.basePort + 'api/UM/UM_User_By_Loc_SelForDDL',

    //Shipment Delivery
    'DIS_Shipment_Delivery_Self_Search': environment.basePath + environment.basePort + 'api/DIS/DIS_Shipment_Delivery_Self_Search',
    'AddShipmentDelivery': environment.basePath + environment.basePort + 'api/DIS/AddShipmentDelivery',
    'UpdateShipmentDelivery': environment.basePath + environment.basePort + 'api/DIS/UpdateShipmentDelivery',
    'Shipment_Delivery_Self_Get': environment.basePath + environment.basePort + 'api/DIS/DIS_Shipment_Delivery_Self_Get',
    'Shipment_Delivery_Self_View': environment.basePath + environment.basePort + 'api/DIS/DIS_Shipment_Delivery_Self_View',
    'Shipment_Delivery_Self_Del': environment.basePath + environment.basePort + 'api/DIS/DIS_Shipment_Delivery_Self_Del',


    //Shipment status    
    'Module_RepotsDwnld': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/UM_Module_PDF_EXCEL',
    'ShipmentStatus_Search': environment.basePath + environment.basePort + 'api/DIS/DIS_ShipmentStatus_Search',
    'ShipmentStatus_POUPItem_Search': environment.basePath + environment.basePort + 'api/DIS/DIS_ShipmentStatus_POUPItem_Search',
    'ShipmentStatus_POUPBarcode_Search': environment.basePath + environment.basePort + 'api/DIS/DIS_ShipmentStatus_POUPBarcode_Search',
    'DIS_Delivery_By_Person_Get': environment.basePath + environment.basePort + 'api/DIS/DIS_Delivery_By_Person_Get',
    'DIS_Delivery_By_Person_Loc_Get': environment.basePath + environment.basePort + 'api/DIS/DIS_Delivery_By_Person_Loc_Get',
    'DIS_Delivery_By_Person_Item_Get': environment.basePath + environment.basePort + 'api/DIS/DIS_Delivery_By_Person_Item_Get',

    //List Of Item Reports
    'ItemList_Rpt': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/DIS_ItemList_Rpt',
    //Item Indent Report
    'ItemIndent_Rpt': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/DIS_ItemIndent_Rpt',

    'DIS_Item_Dispatch_Print_Challan': environment.basePath + environment.baseReportPort + 'api/ReportView/DIS_Item_Dispatch_Print_Challan',
    'View_auditlog_RepotsDwnld': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/UM_Audit_Log_PDF_EXCEL',
    'LocationList_Rpt_PDF_EXCELRepotsDwnld': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/UM_LocationList_Rpt_PDF_EXCEL',

    'BlockDDL': environment.basePath + environment.basePort + 'api/ACM/ACM_Block_byId_SelForDDL',
    'ACM_LocationList_Search': environment.basePath + environment.basePort + 'api/ACM/ACM_LocationList_Search',
    'DIS_Available_Indent_Qunty_Get': environment.basePath + environment.basePort + 'api/DIS/DIS_Available_Indent_Qunty_Get',
    'UM_PageType_Rpt_PDF_EXCEL': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/UM_PageType_Rpt_PDF_EXCEL',


    //Item Return Report
    'ReturnDetails_Rpt': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/DIS_ReturnDetails_Rpt',

    'UpdateDriverDetails': environment.basePath + environment.basePort + 'api/DIS/UpdateDriverDetails',

    //Financial Year DDL
    'FinancialYear_DDL': environment.basePath + environment.basePort + 'api/ACM/ACM_FinancialYear_DDL',

    //Dashboard
    'Dashboard_WEB_GET': environment.basePath + environment.basePort + 'api/DIS/DIS_Dashboard_WEB_GET',
    'Block_District_Indent': environment.basePath + environment.basePort + 'api/DIS/DIS_Block_District_Indent',
    'HOLocations_SelForDDL': environment.basePath + environment.basePort + 'api/ACM/HOLocations_SelForDDL',

    'DIS_Dashboard_Location_Get': environment.basePath + environment.basePort + 'api/ACM/DIS_Dashboard_Location_Get',
    'DIS_Dashboard_District_Get': environment.basePath + environment.basePort + 'api/ACM/DIS_Dashboard_District_Get',
    'DIS_Dashboard_Block_Get': environment.basePath + environment.basePort + 'api/ACM/DIS_Dashboard_Block_Get',

    'DIS_OTP_Get': environment.basePath + environment.basePort + 'api/DIS/DIS_OTP_Get',
    'Delivary_Summary_Rpt': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/DIS_Delivery_Summary_Rpt_PDF_EXCEL',

    'Search_GroupSetup': environment.basePath + environment.basePort + 'api/DIS/DIS_ItemGroup_Mst_Search',
    'Get_GroupSetup': environment.basePath + environment.basePort + 'api/DIS/DIS_ItemGroup_Mst_Get',
    'Update_GroupSetup': environment.basePath + environment.basePort + 'api/DIS/DIS_ItemGroup_Mst_Upd',
    'Insert_GroupSetup': environment.basePath + environment.basePort + 'api/DIS/DIS_ItemGroup_Mst_Ins',
    'Del_GroupSetup': environment.basePath + environment.basePort + 'api/DIS/DIS_ItemGroup_Mst_Del',
    'DDL_ItemGroupList': environment.basePath + environment.basePort + 'api/DIS/DIS_ItemGroup_SelForDDL',

    //Distribute item 
    'DIS_StuItemDistribution_Mst_Search': environment.basePath + environment.basePort + 'api/DIS/DIS_StuItemDistribution_Mst_Search',
    'DIS_StuItemDistribution_Get': environment.basePath + environment.basePort + 'api/DIS/DIS_StuItemDistribution_Get',
    'DIS_StuItemDistribution_Mst_Ins': environment.basePath + environment.basePort + 'api/DIS/DIS_StuItemDistribution_Mst_Ins',
    'DIS_AvailableDistribution_Qunty_Get': environment.basePath + environment.basePort + 'api/DIS/DIS_AvailableDistribution_Qunty_Get',
    'DIS_StuItemDistribution_Mst_Get': environment.basePath + environment.basePort + 'api/DIS/DIS_StuItemDistribution_Mst_Get',
    'DIS_StuItemDistribution_Mst_Upd': environment.basePath + environment.basePort + 'api/DIS/DIS_StuItemDistribution_Mst_Upd',
    'DIS_StuItemDistribution_Mst_Del': environment.basePath + environment.basePort + 'api/DIS/DIS_StuItemDistribution_Mst_Del',

    //Item Demand
    'DIS_ItemDemand_Search': environment.basePath + environment.basePort + 'api/DIS/DIS_ItemDemand_Search',
    'DIS_AvailableDispatch_Qunty_Get': environment.basePath + environment.basePort + 'api/DIS/DIS_AvailableDispatch_Qunty_Get',
    'DIS_ItemDemand_Ins': environment.basePath + environment.basePort + 'api/DIS/DIS_ItemDemand_Ins',
    'DIS_ItemDemand_Get': environment.basePath + environment.basePort + 'api/DIS/DIS_ItemDemand_Get',
    'DIS_ItemDemand_Del': environment.basePath + environment.basePort + 'api/DIS/DIS_ItemDemand_Del',
    'DIS_ItemDemand_Upd': environment.basePath + environment.basePort + 'api/DIS/DIS_ItemDemand_Upd',
    'DIS_ListOfDemand_Consolidated_Rpt': environment.baseReportPath + environment.baseReportPort + 'api/ReportView/DIS_ListOfDemand_Consolidated_Rpt',

    'DIS_ItemGroup_By_Id_SelForDDL': environment.basePath + environment.basePort + 'api/DIS/DIS_ItemGroup_By_Id_SelForDDL',
    'DIS_Item_By_Id_SelForDDL': environment.basePath + environment.basePort + 'api/DIS/DIS_Item_By_Id_SelForDDL',
    'DIS_Dispatch_ReceiveDocs_Ins': environment.basePath + environment.basePort + 'api/DIS/DIS_Dispatch_ReceiveDocs_Ins',
    'DIS_Dispatch_ReceiveDocs_Get': environment.basePath + environment.basePort + 'api/DIS/DIS_Dispatch_ReceiveDocs_Get',
    'DIS_Dispatch_ReceiveDocs_Del': environment.basePath + environment.basePort + 'api/DIS/DIS_Dispatch_ReceiveDocs_Del',


    //Item Group Mapping Year
    'DIS_AcademicYearItemGroup_Search': environment.basePath + environment.basePort + 'api/DIS/DIS_AcademicYearItemGroup_Search',
    'DIS_AcademicYearItemGroup_Ins': environment.basePath + environment.basePort + 'api/DIS/DIS_AcademicYearItemGroup_Ins',
    'DIS_AcademicYearItemGroup_Upd': environment.basePath + environment.basePort + 'api/DIS/DIS_AcademicYearItemGroup_Upd',
    'DIS_AcademicYearItemGroup_Get': environment.basePath + environment.basePort + 'api/DIS/DIS_AcademicYearItemGroup_Get',
    'DIS_AcademicYearItemGroup_View': environment.basePath + environment.basePort + 'api/DIS/DIS_AcademicYearItemGroup_View',
    'DIS_AcademicYearItemGroup_LstDel': environment.basePath + environment.basePort + 'api/DIS/DIS_AcademicYearItemGroup_LstDel',
    'DIS_AcademicYearItemGroup_Del': environment.basePath + environment.basePort + 'api/DIS/DIS_AcademicYearItemGroup_Del',

    'DIS_Dispatch_ReceiveDocs_Details_Get': environment.basePath + environment.basePort + 'api/DIS/DIS_Dispatch_ReceiveDocs_Details_Get',
    'DIS_Dispatch_ReceiveDocs_Status_Upd': environment.basePath + environment.basePort + 'api/DIS/DIS_Dispatch_ReceiveDocs_Status_Upd',
    'ACM_Block_By_Id_SelForDDL': environment.basePath + environment.basePort + 'api/ACM/ACM_Block_By_Id_SelForDDL',

}