import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { API_PATH } from '../utility/api-constant';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  api_path = API_PATH;
  public recordsPerPage = 10;
  numberOnly: any;

  constructor(
    private http: HttpClient,
  ) { }


  public isInteger(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      this.numberOnly = true;
      return false;
    }
    this.numberOnly = false;
    return true;
  }

  getMenuList(request: any): Observable<any> {
    return this.http.post(this.api_path.GetMenuAccess, request)
  }




  logout(): Observable<any> {
    return this.http.get(this.api_path.Logout, {})
  }

  //Location DDL
  Loction_DDL(): Observable<any> {
    return this.http.post(this.api_path.LocationDDL, {})
  }

    //Location DDL
    Item_DDL(): Observable<any> {
      return this.http.post(this.api_path.DIS_ITEM_DDL, {})
    }
    Class_DDL(): Observable<any> {
      return this.http.post(this.api_path.DIS_Class_DDL, {})
    }

  // School Location DDL
  schoolLoction_DDL(): Observable<any> {
    return this.http.post(this.api_path.Teacher_School_SelForDDL, {})
  }
  schoolGender_DDL(): Observable<any> {
    return this.http.post(this.api_path.GenderSelForDDL, {})
  }
  GetDistrictList_DDL(request:any): Observable<any> {
    return this.http.post(this.api_path.DistrictDDL, request)
  }
  GetSchoolForTeahCredentials(): Observable<any> {
    return this.http.post(this.api_path.GetSchoolForTeacherAlert, {})
  }

  GetBlockDDl(): Observable<any> {
    return this.http.post(this.api_path.Block_SelForDDL, {})
  }
  GetStateList_DDL(request:any): Observable<any> {
    return this.http.post(this.api_path.stateDDL, request)
  }

  Block_DDLById(request:any): Observable<any> {
    return this.http.post(this.api_path.Block_DDLById, request)
  }
  DistrictDDLById(request:any): Observable<any> {
    return this.http.post(this.api_path.DistrictDDLById, request)
  }
  getCountryDDL(): Observable<any> {
    return this.http.post(this.api_path.getCountryDDL, {})
  }
  getCommon_StateDDL(): Observable<any> {
    return this.http.post(this.api_path.getCommon_StateDDL, {})
  }
  UserByIdDDL(request:any): Observable<any> {
    return this.http.post(this.api_path.UserByIdDDL, request)
  }
  UserRoleBySelect_IdDDL(request:any): Observable<any> {
    return this.http.post(this.api_path.UserRoleBySelect_IdDDL, request)
  }
  DistrictDDL(): Observable<any> {
    return this.http.post(this.api_path.DistrictDDL, {})
  }
  getCommon_LocationDDL(): Observable<any> {
    return this.http.post(this.api_path.HOLocations_SelForDDL, {})
  }



  
  // **********************Dispatch *******************************

  Dispatch_GetMenuAccess(request: any): Observable<any> {
    return this.http.post(this.api_path.DispatchGetMenuAccess, request)
  }

  DDL_Locationlist(request: any): Observable<any> {
    return this.http.post(this.api_path.Location_DDL, request)
  }

  getLocationList(payload:any) :Observable<any>{
    return this.http.post(this.api_path.UM_Location_By_Id_SelForDDL, payload)
  }
  Profile_Data_Get(request: any): Observable<any> {
    return this.http.post(this.api_path.User_Profile_Data_Get, request)
  }

  BlockDDLById(request: any): Observable<any> {
    return this.http.post(this.api_path.BlockDDL, request)
  }

  

}
