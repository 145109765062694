<div class="mt-2">
        <div class="">
            <!-- <div class="row mb-3">
                <div class="col-md-6">
                    <h5 class="heading1">Select Location</h5>
                </div>
            </div> -->
            <div class="formsfield">
                <form [formGroup]="searchReceivingLocationForm" (ngSubmit)="searchRecLoc()">
                    <div class="row">
                        <div class="col-md-5">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label> Location Name</mat-label>
                                <input matInput formControlName="locationname" maxlength="150">
                            </mat-form-field>
                        </div>
                        <!-- <div class="col-md-4">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Location Type</mat-label>
                                <mat-select formControlName="loctype">
                                    <mat-option [value]="">-- Select --</mat-option>
                                    <mat-option *ngFor="let location of loctypeDDl" [value]="location.ID">
                                        {{location.Text
                                        }} </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div> -->
                        <div class="col-md-3 text-end">
                            <button mat-raised-button color="basic" type="button" class="ms-1 reset" (click)="reset()">Reset</button>
                            <button mat-raised-button color="primary" class="ms-1 search">Search</button>
                        </div>
                    </div>
                </form>
                <div class="row tableview">
                    <div class="col-md-12">
                        <p-table class="full-width" [scrollable]="true" [value]="receivedLocationList">
                            <ng-template pTemplate="caption">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h6 class="heading1 mb-0">
                                            List of Dispatch Locations : (Total no. of Dispatch Locations : {{receivedLocationList?.length?receivedLocationList?.length:0}})
                                        </h6>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="colgroup">
                                <colgroup>
                                    <col style="width:70px">
                                    <col style="width:120px">
                                    <col style="width:120px">
                                    <col style="width:280px">
                                    <col style="width:82px">
                                </colgroup>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="text-center">S.No.</th>
                                    <th>District</th>
                                    <th>Block</th>
                                    <th>Dispatch Location</th>
                                    <th class="text-center">Select</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-data let-i="rowIndex">
                                <tr>
                                    <td class="text-center">{{i+1}}</td>
                                    <td>{{data.districtname}}</td>
                                    <td>{{data.blockname}}</td>
                                    <td>{{data.locationname}}</td>
                                    <td class="text-center"><span class="link pointer" (click)="selectLocation(data)">Select</span></td>

                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="5" class="text-center">
                                        No record found!
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>