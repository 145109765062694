import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { UtilityFunctions } from '../utility/utils';


@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(
    private _snackBar: MatSnackBar,
    public utility:UtilityFunctions
  ) { }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, { duration: 3 * 1000 });
  }

  openSnackBarSuccess(message: string, action: string) {
    this._snackBar.open(message, action, { 
      duration: 3 * 1000, 
      panelClass: ['bg-success'] });
  }

  openSnackBarError(message: string, action: string) {
    this._snackBar.open(message, action, { 
      duration: 3 * 1000,
      panelClass:['bg-danger'] 
    });
  }

  openSnackBarInfo(message: string, action: string) {
    this._snackBar.open(message, action, { 
      duration: 3 * 1000, 
      panelClass: ['bg-info'] });
  }

}
