import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AssessmentService } from 'src/app/services/assessment.service';
import { CommonService } from 'src/app/services/common.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { UtilityFunctions } from 'src/app/utility/utils';

@Component({
  selector: 'app-location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.css']
})
export class LocationSearchComponent implements OnInit {

  searchReceivingLocationForm!: FormGroup;
  @Output() newItemEvent = new EventEmitter<string>();
  @Output() cancelMethod = new EventEmitter<any>();
  locationId: any;
  locType: any;
  @Input('locType') set setData(data: any) {
    this.locType = data;
  };

  @Input('locationId') set setData1(data: any) {
    this.locationId = data;
    console.log(this.locationId);
  };

  loctypeDDl = [
    { ID: 'HO', Text: 'Dept / HQRS Location' },
    { ID: 'ST', Text: 'State Location' },
    { ID: 'DT', Text: 'District Location' },
    { ID: 'BL', Text: 'Block Location' },
    { ID: 'SL', Text: 'School Location' },
  ]
  receivedLocationList: any;
  userData: any;
  authorizationData: any;

  constructor(
    private fb: FormBuilder,
    private _assessmentService: AssessmentService,
    private _toast: ToasterService,
    public utils: UtilityFunctions,
    public service: CommonService,
  ) {
    this.userData = JSON.parse(sessionStorage.getItem('userData')!);
    this.authorizationData = this.utils.getMenuAuthorizationData();
  }

  ngOnInit() {
    this.buildForm();
    this.searchRecLoc();
  }

  buildForm() {
    this.searchReceivingLocationForm = this.fb.group({
      "fk_userId": [''],
      "locationname": [''],
      "loctype": ['']
    })
  }

  reset() {
    this.buildForm();
    this.receivedLocationList = [];
    this.searchRecLoc();
  }

  searchRecLoc() {
    if (this.searchReceivingLocationForm.invalid) {
      this._toast.openSnackBarError(this.utils.errorMsg, 'Error');
    } else {
      let request = this.searchReceivingLocationForm.getRawValue();
      request.fk_userId = this.userData.fk_userId_global,
        request.loctype = this.locType;
      this.service.getLocationList(request).subscribe({
        next: (data) => {
          this.receivedLocationList = data._objLocationData
        },
        error: (error) => {
          this._toast.openSnackBarError(error.statusText, 'Error');
        },
      })
    }
  }

  selectLocation(data: any) {
    this.newItemEvent.emit(data);
    this.cancelledMethod();
  }

  cancelledMethod() {
    this.cancelMethod.emit('');
  }

}
